import type { ReactElement } from "react";

interface IMailToProps {
  address: string;
  subject?: string;
}

export default function MailTo({ address, subject, ...props }: IMailToProps): ReactElement {
  if (subject) {
    return (
      <a
        href={`mailto:${address}?subject=${encodeURIComponent(subject)}`}
        className="text-blue-500 no-underline hover:underline"
        {...props}
      >
        {address}
      </a>
    );
  }

  return (
    <a href={`mailto:${address}`} className="text-blue-500 no-underline hover:underline" {...props}>
      {address}
    </a>
  );
}
